import React, {Component} from 'react';
import ReactMarkdown from 'react-markdown';     // 解析 markdown
import remarkGfm from 'remark-gfm';             // markdown 对表格/删除线/脚注等的支持
import MarkNav from 'markdown-navbar';          // markdown 目录
import 'markdown-navbar/dist/navbar.css';
import {Button, Col, Menu, Row} from "lole-ui";
import rehypeRaw from "rehype-raw";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import {BackTop, Drawer} from "antd";
import './route.css';
import MenuItem from "lole-ui/dist/components/Menu/menuItem";
import {Link} from "react-router-dom";

class Design extends Component {

    constructor(props) {
        super(props);
        this.state={
            SourceData: '',
            visible: false,
        }
    }

    componentWillMount() {
        let $this = this;
        let xmlhttp = new XMLHttpRequest();

        let { id } = this.props.match.params;
        console.log('mount',id)

        let path;

        if (id === '0')
            path = '1 - 介绍';
        if (id === '1')
            path = '2 - 设计';
        if (id === '2')
            path = '3 - 最佳实践';
        if (id === '3')
            path = '4 - 全局样式';

        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                $this.setState({
                    SourceData: xmlhttp.responseText
                })
            }
        }
        // 文件目录在 public/static/test.md 这里不需要写 public 因为打包之后没有此目录。
        xmlhttp.open("GET", `/md/design/${path}.md`, true);
        xmlhttp.send();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let $this = this;
        let xmlhttp = new XMLHttpRequest();

        let { id } = this.props.match.params;

        let path;

        if (id === '0')
            path = '1 - 介绍';
        if (id === '1')
            path = '2 - 设计';
        if (id === '2')
            path = '3 - 最佳实践';
        if (id === '3')
            path = '4 - 全局样式';

        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                $this.setState({
                    SourceData: xmlhttp.responseText
                })
            }
        }
        // 文件目录在 public/static/test.md 这里不需要写 public 因为打包之后没有此目录。
        xmlhttp.open("GET", `/md/design/${path}.md`, true);
        xmlhttp.send();
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    bindSelectMenu = (id) => {
        //console.log('id',id)
        this.props.history.push({
            pathname:'/design/'+id
        })
    }

    render() {

        const style = {
            height: 40,
            width: 40,
            lineHeight: '40px',
            borderRadius: 4,
            backgroundColor: '#1088e9',
            color: '#fff',
            textAlign: 'center',
            fontSize: 14,
        };

        return (
            <div>
                <div style={{marginTop:'10vh'}}>
                    <Row>
                        <Col span={4} >
                            <div className="leftSide" style={{position:'fixed'}}>
                                <MarkNav
                                    className="toc-list"
                                    source={this.state.SourceData}
                                    ordered={true}
                                />
                            </div>
                        </Col>
                        <Col span={20}>
                            <div className="markdown-body content">

                                <div className='lole-ui-read-btn'>
                                    <Button onClick={this.showDrawer} btnType='primary' shape='circle'>目录</Button>
                                </div>

                                <ReactMarkdown
                                    children={this.state.SourceData}
                                    remarkPlugins={[remarkGfm]}
                                    escapeHtml={false}
                                    rehypePlugins={[rehypeRaw]}
                                    components={{
                                        code({node, inline, className, children, ...props}) {
                                            const match = /language-(\w+)/.exec(className || '')
                                            return !inline && match ? (
                                                <SyntaxHighlighter
                                                    children={String(children).replace(/\n$/, '')}
                                                    style={dark}
                                                    language={match[1]}
                                                    PreTag="div"
                                                    {...props}
                                                />
                                            ) : (
                                                <code className={className} {...props}>
                                                    {children}
                                                </code>
                                            )
                                        }
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <Drawer
                    title="目录"
                    placement='right'
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                >
                    <Menu className='lole-ui-read-drawer'
                          mode='vertical'
                          defaultIndex={this.props.match.params.id}
                    >
                        <MenuItem index='0'>
                            <Link to='/design/0'>介绍</Link>
                        </MenuItem>
                        <MenuItem index='1'>
                            <Link to='/design/1'>设计</Link>
                        </MenuItem>
                        <MenuItem index='2'>
                            <Link to='/design/2'>最佳实践</Link>
                        </MenuItem>
                        <MenuItem index='3'>
                            <Link to='/design/3'>全局样式</Link>
                        </MenuItem>
                    </Menu>
                </Drawer>
            </div>
        );
    }

}

export default Design;

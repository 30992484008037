import React, {Component} from 'react';

class UI extends Component {
    render() {
        return (
            <div style={{height:'100vh',marginTop:'10vh',border:'none'}}>
                <iframe width='100%' height='100%' src='/md/storybook-static/index.html'/>
            </div>
        );
    }
}

export default UI;

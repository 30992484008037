import React, {Component} from 'react';
import {Animator, Button, Col, Divider, Row, Space} from "lole-ui";
import './route.css';


class Main extends Component {

    constructor(props) {
        super(props);
        this.state={
            showLole:false,
        }
    }

    bindMouseEnter=()=>{
        this.setState({showLole:true})
    }
    bindMouseLeave=()=>{
        this.setState({showLole:false})
    }

    render() {
        return (
            <div>

                <div className='lole-ui-bg'>
                    <div style={{height:'60vh',textAlign:'center'}}>

                        <Space>
                            <div className='lole-ui-main-header-wrapper'
                                 onMouseEnter={this.bindMouseEnter}
                                 onMouseLeave={this.bindMouseLeave}
                            >

                                <p className='lole-ui-main-title' style={{display:'flex'}}>
                                    lo
                                    <Animator
                                        in={this.state.showLole}
                                        timeout={300}
                                        animation='zoom-in-left'
                                    >
                                        <span className='lole-ui-main-title-span'>ve</span>
                                    </Animator>
                                    le
                                    <Animator
                                        in={this.state.showLole}
                                        timeout={300}
                                        animation='zoom-in-left'
                                    >
                                        <span className='lole-ui-main-title-span'>tter</span>
                                    </Animator>
                                    &nbsp;UI</p>

                                <p className='lole-ui-main-text'>献给每一位前端工程师的情书</p>

                                <Space>
                                    <a target='_blank' href='/md/storybook-static/index.html'>
                                        <Button style={{margin:'10px'}} size='lg' btnType='primary' shape='circle'>
                                            开始使用
                                        </Button>
                                    </a>
                                    <Button onClick={()=>this.props.history.push('/design/0')} style={{margin:'10px'}} size='lg' btnType='default' shape='circle'>设计文档</Button>
                                </Space>
                            </div>
                        </Space>

                    </div>

                    <div style={{height:'40vh'}}>


                        <p className='lole-ui-home-font'>将浪漫揉进lole UI写成情书，递给下一位来访者</p>

                    </div>

                </div>

                <div style={{height:'90vh'}}>
                    <h2 className='lole-ui-main-h2' style={{marginTop:'10vh'}}>设计语言与框架</h2>

                    {/*第一个*/}
                    <div className='lole-ui-main-card' style={{height:'40vh',margin:'40px'}}>
                        <Row>
                            <Col span={12}>
                                <div style={{textAlign:'left'}}>
                                    <p className='lole-ui-main-card-title'>设计价值观</p>
                                    <p className='lole-ui-main-card-span'>lole UI设计准则时刻都遵循这四点：主题、行为、拓展、浪漫。我们的设计均为主题服务，我们的主题为“情书”，主色调粉白，依次组件导出都充斥着令人心跳加速的粉色；
                                        我们忠于主题，我们高于主题，lole UI的所有组件设计原则都遵顼用户行为驱动，我们制作UI组件，但我们绝不教用户到底该怎么使用组件；
                                        lole UI预留接口丰富，适配二次开发需求，同时在设计中我们揉入了我们的浪漫：Pink and White, Love and Peace。
                                    </p>
                                    <Button onClick={()=>this.props.history.push('/design/1')} className='lole-ui-main-card-btn-link' btnType='link' style={{background:'transparent'}}>不想看看吗?</Button>
                                </div>
                            </Col>

                            <Col span={3} style={{margin:'auto',textAlign:'center'}}>
                                <img width='110' src='https://s1.ax1x.com/2022/04/02/qoWFkF.png'/>
                                <p className='lole-ui-main-card-four-img-name'>主题</p>
                            </Col>

                            <Col span={3} style={{margin:'auto',textAlign:'center'}}>
                                <img width='110' src='https://s1.ax1x.com/2022/04/02/qofbGQ.png'/>
                                <p className='lole-ui-main-card-four-img-name'>行为</p>
                            </Col>

                            <Col span={3} style={{margin:'auto',textAlign:'center'}}>
                                <img width='110' src='https://s1.ax1x.com/2022/04/02/qoWkY4.png'/>
                                <p className='lole-ui-main-card-four-img-name'>拓展</p>
                            </Col>

                            <Col span={3} style={{margin:'auto',textAlign:'center'}}>
                                <img width='110' src='https://s1.ax1x.com/2022/04/02/qoWAfJ.png'/>
                                <p className='lole-ui-main-card-four-img-name'>浪漫</p>
                            </Col>

                        </Row>

                    </div>

                    {/*二三，合并一行*/}

                    <Row>
                            <Col span={12}>
                                <div className='lole-ui-main-card-small lole-ui-one-card' style={{marginLeft:'40px'}}>
                                    <div>
                                        <p className='lole-ui-main-card-small-title'>设计指引</p>
                                        <Space className='lole-ui-main-card-small-space'>
                                            <Button onClick={()=>this.props.history.push('/design/3')} btnType='link' style={{background:'transparent'}}>全局样式</Button>
                                        </Space>
                                    </div>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div className='lole-ui-main-card-small lole-ui-two-card' style={{marginLeft:'40px'}}>
                                    <p className='lole-ui-main-card-small-title'>引导文档</p>
                                    <Space className='lole-ui-main-card-small-space'>
                                        <Button onClick={()=>this.props.history.push('/doc/0')} btnType='link' style={{background:'transparent'}}>lole UI of React</Button>
                                    </Space>
                                </div>
                            </Col>
                        </Row>



                </div>

                <div style={{height:'90vh'}}>

                    <Row>
                        <Col span={8}>

                            <div className='lole-ui-main-more-card' >

                                <div className='lole-ui-main-more-card-img lole-ui-small-one-card'/>
                                <Divider style={{margin:0}}/>
                                <div className='lole-ui-small-card'>
                                    <Button onClick={()=>this.props.history.push('/design/0')} btnType='link' style={{background:'transparent'}} className='lole-ui-main-more-card-title'>设计文档</Button>
                                    <p className='lole-ui-main-more-card-content'>在我们的答卷中寻找灵感</p>
                                </div>
                            </div>

                        </Col>

                        <Col span={8}>
                            <div className='lole-ui-main-more-card' >
                                <div className='lole-ui-main-more-card-img lole-ui-small-two-card'></div>
                                <Divider style={{margin:0}}/>
                                <div className='lole-ui-small-card'>
                                    <Button target='_blank' href='/md/storybook-static/index.html' btnType='link' style={{background:'transparent'}} className='lole-ui-main-more-card-title'>lole UI组件库</Button>
                                    <p className='lole-ui-main-more-card-content'>Let's Start.</p>
                                </div>
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className='lole-ui-main-more-card' >
                                <div className='lole-ui-main-more-card-img lole-ui-small-three-card'></div>
                                <Divider style={{margin:0}}/>
                                <div className='lole-ui-small-card'>
                                    <Button btnType='link' href='https://github.com/XIYExi/xiOn-ui' style={{background:'transparent'}} className='lole-ui-main-more-card-title'>GitHub</Button>
                                    <p className='lole-ui-main-more-card-content'>Fork我们的源码，让我们一起进步</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>

                            <div className='lole-ui-main-more-card' >

                                <div className='lole-ui-main-more-card-img lole-ui-small-four-card'></div>
                                <Divider style={{margin:0}}/>
                                <div className='lole-ui-small-card'>
                                    <Button btnType='link' style={{background:'transparent'}} className='lole-ui-main-more-card-title'>lole UI 原生</Button>
                                    <p className='lole-ui-main-more-card-content'>尚未适配,敬请期待</p>
                                </div>
                            </div>

                        </Col>

                        <Col span={8}>
                            <div className='lole-ui-main-more-card' >
                                <div className='lole-ui-main-more-card-img lole-ui-small-five-card'></div>
                                <Divider style={{margin:0}}/>
                                <div className='lole-ui-small-card'>
                                    <Button btnType='link' style={{background:'transparent'}} className='lole-ui-main-more-card-title'>lole UI AngularJS</Button>
                                    <p className='lole-ui-main-more-card-content'>尚未适配,敬请期待</p>
                                </div>
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className='lole-ui-main-more-card' >
                                <div className='lole-ui-main-more-card-img lole-ui-small-six-card'></div>
                                <Divider style={{margin:0}}/>
                                <div className='lole-ui-small-card' >
                                    <Button btnType='link' style={{background:'transparent'}} className='lole-ui-main-more-card-title'>lole UI Vue</Button>
                                    <p className='lole-ui-main-more-card-content'>尚未适配,敬请期待</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </div>

            </div>
        );
    }
}

export default Main;

import {Link, Redirect, Route, Switch, withRouter} from "react-router-dom";
import React from "react";
import Main from "./route/Main";
import {Col, Content, Footer, Header, Layout, Menu, Row} from "lole-ui";
import MenuItem from "lole-ui/dist/components/Menu/menuItem";
import './App.css';
import Design from "./route/Design";
import "antd/dist/antd.css";
import Doc from "./route/Doc";
import UI from "./route/UI";

class App extends React.Component{

  render() {


    return (
        <div className="App">
          <Layout>
            <Header style={{background: 'rgba(249,204,226,0.2)', position: 'fixed', zIndex: 1, width: '100%'}}>
              <Row>
                <Col span={3}>
                  <span className='lole-ui-main-svg' onClick={()=>this.props.history.push('/')}>
                    <img width='70px' height='70px' src='https://s1.ax1x.com/2022/04/02/qo9cSs.png'/>
                    <span style={{
                      margin: 'auto',
                      fontSize: '23px',
                      fontWeight: 'bold',
                      verticalAlign: 'middle',
                    }}>lole</span>
                  </span>
                </Col>
                <Col span={5}/>
                <Col span={12}/>
                <Col span={4}>
                  <Menu style={{color: '#000'}}>
                    <MenuItem><Link to='/design/0'>设计</Link></MenuItem>
                    <MenuItem><Link to='/doc/0'>文档</Link></MenuItem>
                    <MenuItem><a target='_blank' href='/md/storybook-static/index.html'>组件</a></MenuItem>
                  </Menu>
                </Col>
              </Row>
            </Header>

            <Content className='lole-ui-web-content' style={{padding: '10px'}}>
              <Switch>
                <Route exact path='/' component={Main}/>
                <Route exact path='/design/:id' component={Design}/>
                <Route exact path='/doc/:id' component={Doc}/>
              </Switch>
            </Content>

            <Footer style={{textAlign: 'center'}}>
              Designed By <code>rd /s/q C:\Program Files\Java</code>
            </Footer>


          </Layout>
        </div>
    );
  }
}

export default withRouter(App);
